/* This SCSS file is responsible for defining colors we use in risk map and table reports.
 */
@import 'brand_colors';

$risk-map-not-evaluated-regular-color: #808080;
$risk-map-not-evaluated-regular-outline: #333;

$risk-map-very-low-regular-color: #3366ff;
$risk-map-very-low-regular-outline: #002db3;
$risk-map-very-low-hover-color: #99b3ff;
$risk-map-very-low-hover-outline: #002db3;
$risk-map-very-low-click-color: #002699;
$risk-map-very-low-click-outline: $brand-black;

$risk-map-low-regular-color: #26ad92;
$risk-map-low-regular-outline: #006551;
$risk-map-low-hover-color: #45c4aa;
$risk-map-low-hover-outline: #006551;
$risk-map-low-click-color: #147f6a;
$risk-map-low-click-outline: $brand-black;

$risk-map-medium-regular-color: #f8ea0e;
$risk-map-medium-regular-outline: #adb311;
$risk-map-medium-hover-color: #f0f80e;
$risk-map-medium-hover-outline: #adb311;
$risk-map-medium-click-color: #ded543;
$risk-map-medium-click-outline: #999117;

$risk-map-high-regular-color: #ff9900;
$risk-map-high-regular-outline: #993300;
$risk-map-high-hover-color: #fbaf3c;
$risk-map-high-hover-outline: #993300;
$risk-map-high-click-color: #ff6600;
$risk-map-high-click-outline: $brand-black;

$risk-map-veryhigh-regular-color: #cc3300;
$risk-map-veryhigh-regular-outline: #330000;
$risk-map-veryhigh-hover-color: #ff3300;
$risk-map-veryhigh-hover-outline: #330000;
$risk-map-veryhigh-click-color: #9e2a03;
$risk-map-veryhigh-click-outline: $brand-black;

$risk-map-tpp-regular-color: #808080;
$risk-map-tpp-regular-outline: #333;
$risk-map-tpp-hover-color: $hover-color;
$risk-map-tpp-hover-outline: #666;
$risk-map-tpp-click-color: #515050;
$risk-map-tpp-click-outline: $brand-black;

$risk-table-cell-very-low-color: #3366ff;
$risk-table-cell-low-color: #26ad92;
$risk-table-cell-medium-color: #f8ea0e;
$risk-table-cell-high-color: #ff9900;
$risk-table-cell-very-high-color: #cc3300;
$risk-table-cell-not-assessed-color: #808080;

$risk-map-not-assessed-regular-color: #808080;
$risk-map-not-assessed-regular-outline: #333;
$risk-map-not-assessed-hover-color: $hover-color;
$risk-map-not-assessed-hover-outline: #666;
$risk-map-not-assessed-click-color: #515050;
$risk-map-not-assessed-click-outline: $brand-black;
