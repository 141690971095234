@import 'brand_colors';

$trial-bar-height: 50px;
$company-header-height: 55px;
$page-title-bar-height: 50px;
$nav-bar-height: 40px;
$nav-bar-item-padding-top: 10px;
$nav-bar-item-padding-bottom: 10px;
$nav-bar-item-border-top: 4px;
$nav-bar-item-font-size: 14px;
$header-banner-height: 60px;
$header-banner-padding-top: 10px;
$header-banner-padding-bottom: 15px;
$header-banner-padding-minified: 10px;
$header-banner-font-size: 24px;
$header-banner-font-size-minified: 14px;
$process-explorer-bar-height: 50px;
$footer-height: 77px;
$footer-margin-top: 40px;
$footer-margin-bottom: 20px;
$footer-font-size: 12px;
$box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
$button-border-box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
$border-radius: 4px;
$confidence-level-item-size: 16px;
$scrollbar-size: 13px;

/**
 * Use this to calculate the REM size to make the code more readable.
 *
 * Note: The REM size is based on the font size of the root <html> tag. So `1.0rem` is 14px, `1.5rem` is 21px, etc.
 * Use this for font sizes so that we can one day do a better job of helping people who have vision issues.
 *
 * tl;dr This should be used for all font sizes that don't appear in the quick panel.
 */
@function calculateRem($size) {
  $remSize:  calc($size / 14px); // Divide by 14px

  @return #{$remSize}rem;
}

/**
 * Use this to calculate the EM size to make the code more readable. Use the size you want on a regular page, not in the quick panel.
 *
 * Note: The EM size is based on the font size of the previous tag that specified a px size. So `1.0em` is 14px in a
 * full sized editor, but 12px in the quick panel. `1.5em` is 21px on a full sized editor, but 18px, etc.
 *
 * Use this for font sizes so that we can one day do a better job of helping people who have vision issues.
 *
 * tl;dr This should be used for all font sizes that MIGHT appear in the quick panel.
 */
@function calculateEm($size) {
  $emSize: calc($size / 14px); // Divide by 14px

  @return #{$emSize}em;
}

/**
 * Use this to create the standard shadow on panels.
 */
@mixin shadow() {
  border-radius: 4px;
  box-shadow: $box-shadow;
}

@mixin thinScrollBar() {
  scrollbar-color: $brand-mild-grey;
  scrollbar-width: thin;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}

@mixin navBarItem() {
  color: $brand-black;
  font-family: 'Roboto', sans-serif;
  margin-bottom: -6px;
  padding-bottom: 5px;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    background-color: $brand-light-grey;
    text-decoration: none;
  }

  a[disabled] {
    color: #b1b2b2 !important;
    font-style: italic;
  }

  a:not([href]):not([class]) {
    color: $brand-black;
  }
}

@mixin navBarSelected() {
  border-bottom: 4px solid $brand-highlight-color;
  color: $brand-black;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  text-decoration: none;

  a:hover {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
}


