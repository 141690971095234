@import 'brand_colors';
@import 'globals';

#adminInternal {
  margin: 0 10px;

  .tab-nav-bar {
    margin: 0;
  }

  #resultsTable, #companyUsersTable, #usersTable {
    width: 100%;
    table-layout: fixed;
  }

  .input-with-button-container {
    position: relative;
    font-weight: normal;
    font-size: calculateRem(12px);

    input {
      padding-right: 25px;
    }

    .icon-wrapper {
      cursor: pointer;
      color: $brand-black;
      opacity: 0.3;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity 200ms ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }
}
