@import 'brand_colors';
@import 'globals';

#homePageDiv {
  padding-bottom: 20px;
}

.home-page-info-card,
.home-page-project-info-card,
.home-page-notification-entry,
.home-page-alerts-entry {
  cursor: pointer;
}

#pendingTrainingInfoCard .home-page-info-card-details-line-title {
  white-space: normal;
}

.home-page-projects-header {
  margin-top: 63px;
}

.home-page-projects-header-title {
  font-family: 'Roboto', sans-serif;
  font-size: calculateRem(24px);
  font-weight: bold;
}

.home-page-projects-header-title-count {
  font-weight: normal;
}

.home-page-projects-header-link {
  color: $brand-primary-color;
  font-size: calculateRem(14px);
  font-weight: 600;
  margin-left: 9px;
}

.home-page-projects-header-button {
  float: right;
  margin-top: 0;
}

.home-page-projects-header-button-disabled {
  border: solid 1px $brand-highlight-color;
  background-color: $brand-primary-color;

  span {
    color: $brand-mild-grey !important;
  }
}

.home-page-project-info-card {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  min-height: 100px;
  padding-left: 30px;
  padding-right: 10px;
  width: 100%;
  word-wrap: break-word;

  &.containsSkeleton {
    justify-content: left;
  }
}

.home-page-project-info-card-vertical-separator {
  border: solid thin $brand-mild-grey;
  height: 53px;
  width: 0.5px;
}

.home-page-project-info-card-left-title {
  color: $brand-primary-color;
  font-size: calculateRem(18px);
  font-weight: 600;
}

.home-page-project-info-card-left-details {
  font-size: calculateRem(12px);
  letter-spacing: 0;
}

.home-page-project-info-card-right-title {
  font-size: calculateRem(16px);
  font-weight: 600;
}

.home-page-project-info-card-right-details {
  font-size: calculateRem(12px);
}

.home-page-alerts-card {
  flex-grow: 1;
  margin-top: 15px;
  min-width: 220px;
}

.home-page-alerts-card-title {
  font-family: 'Roboto', sans-serif;
  font-size: calculateRem(18px);
  font-weight: bold;
}

.home-page-alerts-card-error-icon-red, .home-page-alerts-card-error-icon-green {
  font-size: calculateRem(16px);
  line-height: 1.25;
  margin-right: 5px;
  text-align: center;
}

.home-page-alerts-card-error-icon-red {
  color: $error-color;
  height: 20px;
}

.home-page-alerts-card-error-icon-green {
  color: $success-color;
}

$alerts-header-height: 65px;

.home-page-alerts-card-header {
  border-bottom: thin solid $brand-mild-grey;
  padding-top: 20px;
  padding-left: 20px;
  height: $alerts-header-height;
}

.home-page-alerts-card-link {
  display: none;
  float: right;
  margin-top: 3px;
}

.home-page-alerts-entry {
  padding-left: 20px;
  padding-right: 20px;
}

.home-page-alerts-entry-title {
  color: $brand-primary-color;
  font-size: calculateRem(12px);
  font-weight: 600;
}

.home-page-alerts-entry-details,
.home-page-alerts-entry-due-date,
.home-page-alerts-entry-due-date-type {
  font-size: calculateRem(12px);
}

.home-page-alerts-entry-details {
  margin-left: 5px;
}

.home-page-alerts-entry-due-date {
  float: right;
  margin-left: 25px;
}

.home-page-alerts-entry-left-div {
  display: inline-block;
  padding-top: 12.5px;
  padding-bottom: 10px;
}

.home-page-alerts-entry-separator {
  border-top: solid thin $brand-mild-grey;
  margin-left: -20px;
  margin-right: -20px;
}

.home-page-alerts-card-separator {
  border-bottom: solid thin $brand-mild-grey;
  margin-top: 10px;
}

.home-page-alerts-card-details {
  height: 248px;
  overflow: auto;
}

.home-page-notifications-panel-container {
  display: inline-block;
  float: right;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-right: 0;
}

.home-page-notifications-panel {
  position: sticky;
  top: 15px;
}

.home-page-summary-panel {
  display: inline-block;

  // To help with QI-4014
  .col {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.shadow {
  @include shadow();

  background-color: white;
}

.card-mt {
  margin-top: 15px;
}

// The extra 60px is to take care of the "Frequently Viewed / Activity" header
.home-page-notifications-panel-data {
  overflow: auto;
  height: calc(
    100vh
    - 60px
    - #{$page-title-bar-height}
    - #{$footer-height}
    - #{$footer-margin-bottom}
  );
}

.home-page-notification-entry {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.home-page-notification-entry-title {
  color: $brand-primary-color;
  font-size: calculateRem(14px);
  font-weight: 600;
}

.home-page-notification-entry-user,
.home-page-notification-entry-last-activity {
  font-size: calculateRem(12px);
}

.home-page-notification-entry-separator {
  border-top: solid thin $brand-mild-grey;
}

.home-page-notification-panel-separator {
  border-bottom: solid thin $brand-mild-grey;
  margin-left: 0;
  margin-top: -10px;
}

.home-page-nav-bar-item {
  padding-top: 20px !important;
  padding-bottom: 17px !important;
}

.home-page-alerts-details {
  height: 250px;
  overflow: auto;
}

.home-page-empty-data {
  color: $brand-dark-grey;
  font-size: calculateRem(14px);
}

.home-page-empty-data-frequently-viewed,
.home-page-empty-data-activity,
.home-page-empty-data-alerts {
  margin: 0 auto;
  word-break: break-word;
}

.home-page-empty-data-frequently-viewed {
  width: 170px;
}

.home-page-empty-data-activity {
  width: 190px;
}

.home-page-empty-data-alerts {
  width: 200px;
}

.home-page-empty-alerts {
  padding-top: 85px;
  text-align: center;
}

.home-page-empty-notifications {
  padding-top: 320px;
  text-align: center;
}

.home-page-error-bar {
  margin-top: 20px;
  margin-bottom: 5px;
}

.home-page-info-card:hover,
.home-page-project-info-card:hover,
.home-page-alerts-entry:hover,
.home-page-notification-entry:hover {
  background-color: $brand-light-grey;
}

#pendingDraftsLink:hover {
  text-decoration: underline;
}

.terms-modal-on-top {
  /* Z-index is forced so it appears above the shimmering and the text on the home page (See QI-5571 and QI-6079). */
  z-index: 4000 !important;
}
