/* This SCSS file is responsible for all of the CSS on library pages.
 */
@import "globals";
@import "brand_colors";
@import "import";

$background-color: #f0f4f5;

#library-list-page .product-buttons-container {
  margin-right: -20px;
  margin-bottom: 0;
  padding-top: 28px;
  float: right;
}

#library-list-page .column-selection-tooltip-title {
  border-bottom: solid thin #dadada !important;
}

/* stylelint-disable-next-line selector-id-pattern */
#library-list-page #bodyDiv {
  background: white !important;
}

/* Start Library table view styles   */

.library-sub-top-tab {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px $brand-mild-grey;
  background-color: #f0f4f5 !important;
  padding-left: 60px;
  padding-top: 10px;
  padding-bottom: 10px;

  .library-tables-actions-container {
    display: inline-block;
    float: right;
    margin-right: 60px;
  }
}

.show-archived-div {
  position: relative;
  display: inline-flex;
  align-items: baseline;
  gap: 10px;

  span {
    color: $brand-dark-grey;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
  }

  .show-archived-checkbox-toggle {
    top: 3px;
    margin: unset;
    display: inline-flex;
    align-items: center;
  }
}

.right-bar-header-part {
  display: flex;
  flex-direction: row;

  .label {
    margin: auto;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
  }
}

.category-filter-outer-div {
  width: unset;
  display: flex;
  flex-direction: row;
  margin-left: 55px;

  .select-label {
    margin: auto 10px auto 0;
    font-size: 14px;
    font-weight: 400;
    color: $brand-dark-grey;
  }

  .category-select {
    margin: auto;
    max-width: 275px;
  }
}

/* End Library table view styles   */
.bar-content {
  float: left;
  position: absolute;
  margin-top: 4.2em;
}

.create-from-project-error-bar {
  width: 100%;
}

.create-attributes-container {
  margin-top: 5rem !important;

  .create-attributes-header {
    margin-bottom: 0;
  }
}

.link-to-library {
  float: right;
  margin-top: 35px;
}

.library-status-warning {
  padding: inherit;
}

.materials-count {
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 25px;
  display: block;
}

.changed-attributes-container {
  margin: 2em 0;

  /* stylelint-disable-next-line selector-class-pattern */
  .svg-inline--fa {
    color: $warning-color;
  }

  .changed-attributes {
    margin-left: 1em;
  }
}

.changed-attributes-bottom-border {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 20px;
  background-color: #0000;
  border-bottom: 1px solid $brand-slight-grey;
}

.material-attributes-container {
  margin-top: 2em;
}

.material-options-container {
  overflow-y: auto;
  max-height: 380px !important;
  padding: 40px 175px !important;

  h2 {
    margin-bottom: 5px;
  }

  p {
    color: $brand-dark-grey;
  }

  .keep-synced-info {
    display: block;
    margin-left: 3.6em;
    color: $brand-dark-grey;
  }
}

#swap-project-body,
#unlink-material-attribute-body,
#link-project-material-attribute-body,
#sync-project-body,
#unsync-material-body,
#unlink-material-body {
  .k-popup {
    .k-list-optionlabel {
      display: none !important;
    }
  }

  .k-item {
    margin: 0 !important;
    padding: 0 !important;

    &.k-state-selected {
      background-color: #f0f4f5 !important;
    }

    .dropdown-item {
      padding: 10px !important;

      .action-dropdown-item {
        display: block !important;
        margin-top: 3px !important;
        font-size: 12px !important;
        line-height: 16px !important;
        color: $brand-dark-grey !important;
      }
    }

    &:hover {
      background-color: $brand-mild-grey !important;

      .dropdown-item {
        background-color: $brand-mild-grey !important;
      }
    }

    border-bottom: 1px solid $brand-mild-grey !important;
  }

  .k-grid-content {
    overflow-y: auto !important;
  }

  .k-grid-header {
    padding: 0 !important;
  }

  /* stylelint-disable no-descending-specificity */
  .k-animation-container {
    &.k-animation-container-shown {
      border: 1px solid #1FBCFF;
      border-radius: 3px !important;
    }

    .k-list-content {
      overflow-y: auto;
      max-height: 300px !important;
    }

    .k-list-item {
      padding: 12px;
      background-color: #fff !important;

      .dropdown-item {
        padding: unset;
        background-color: #fff !important;

        .item-part-1 {
          margin-right: 6px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .item-part-2 {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: #434952;
        }
      }

      &:hover {
        background-color: #F0F4F5 !important;

        .dropdown-item {
          background-color: #F0F4F5 !important;
        }
      }

      &.k-selected {
        background-color: #F0F4F5 !important;

        .dropdown-item {
          background-color: #F0F4F5 !important;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #DADADA;
      }
    }
  }
}

@media (max-width: 768px) {
  .k-animation-container-shown{
    top: calc(40% + 30px) !important;
    z-index: 1000 !important;
  }
}

/* stylelint-disable no-descending-specificity */
#unlink-material-body,
#unsync-material-body,
#unlink-material-attribute-body,
#link-project-material-attribute-body {
  .container-spacer {
    height: 52px;
  }
}

.chevron-button {
  margin-right: 20px;

  a {
    cursor: pointer;
    text-decoration: none;
    /* stylelint-disable-next-line selector-class-pattern */
    .svg-inline--fa {
      color: $brand-dark-grey;
    }
  }
}

.reconciliation-table-outer-div {
  margin-top: 25px !important;

  .material-attribute-cell-secondary, .specification-cell-secondary {
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
    color: #434952;
  }

  .specification-cell-outer-div {
    width: 100%;

    /* stylelint-disable-next-line selector-class-pattern */
    .svg-inline--fa {
      float: right;
      margin-top: 0.4em;
      color: $brand-medium-grey;
    }
  }

  .default-dropdown-item {
    color: $brand-medium-grey;

    &:hover {
      background-color: #f0f4f5 !important;
    }
  }

  .k-header {
    background-color: #f0f4f5;
    border: 1px solid #dadada;

    td {
      background-color: #f0f4f5 !important;
    }

    .clear-left-border {
      width: 100%;
      display: block;
      border-left: none !important;
    }

    .clear-right-border {
      width: 100%;
      display: block;
      border-right: none !important;
    }
  }

  .k-grid th:first-child,
  .k-grid td:first-child {
    border-left-width: thin !important;
  }
}

.project-material-modal {
  .modal-footer {
    padding: 0 !important;
  }

  .sync-attribute {
    display: flex;
    justify-items: center;
    align-items: center;
    margin-bottom: 15px;
  }

  .sync-attribute-description {
    margin-top: -0.625em;
    margin-left: 3.7rem;
  }

  .project-material-modal-body {
    background-color: $brand-light-grey;
  }

  .specification-section {
    padding: 35px;
  }

  .specification-section-heading > div > h2 {
    margin-bottom: 2px;
  }

  .specification-description {
    color: $brand-dark-grey;
  }

  .specification-section-content {
    margin: 0 !important;
  }

  .project-material-modal-head.col {
    padding-left: 0;
    font-size: 1.8rem;
  }

  .project-material-modal-head.col span {
    font-size: 1rem !important;
    font-weight: normal;
  }

  .project-material-modal-head.col h4 {
    font-size: 1.3rem;
    font-weight: bold;
    font-family: Roboto, sans-serif;
  }
}

.material-library-wizard-div {
  z-index: 2000;

  /* stylelint-disable-next-line selector-id-pattern */
  #selectMaterialNavItem.import-nav-inactive, #createPreferencesNavItem.import-nav-inactive {
    pointer-events: none;
    text-decoration: none;
    color: gray;
  }
}

.material-library-wizard-step-inner-container {
  padding: 20px 40px;
  min-height: inherit;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.synced-attributes,
.synced-attributes .section-content-wrapper {
  padding: 10px 10px 0;
  margin: -10px -10px 7px;
  border-radius: 5px;
  background-color: $background-color;
}

div.synced-attributes select,
div.synced-attributes textarea,
div.synced-attributes .form-control {
  pointer-events: none;
  border: none;
  background-color: $background-color !important;
  font-weight: bold;
  margin-left: -10px;
  resize: none;
}

div.synced-attributes input[type="checkbox"] {
  margin-left: 0 !important;
}

/* stylelint-disable property-no-vendor-prefix */
div.synced-attributes select {
  padding-left: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* stylelint-enable property-no-vendor-prefix */

.library-status-bar {
  display: flex;
  margin-left: -5px;
  flex-direction: row;
  align-items: center;

  .disabled-link {
    color: $brand-dark-grey;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.library-status-bar-outer-div {
  .section {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.library-options-button {
  margin: 0;
  background-color: transparent;
  border: none;
  font-size: 1em;
  font-weight: bold;
  color: $brand-medium-grey;
}

.library-options-button:hover {
  background-color: #ededed;
  color: $brand-medium-grey;
}

.material-general-header {
  margin-left: -8px;
}

.material-library-wizard-steps-container {
  @include rounded-border;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin-top: -2px;
  min-height: 550px;
  width: 100%;
}

.action-buttons-box {
  margin: 0;
  padding: 10px 15px;
  width: 100%;
  border-top: 1px solid #dee2e6;
}

.empty-table-state {
  min-height: 75%;
  display: flex;
  margin-top: 60px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.library-materials-table {
  overflow-y: auto;
  max-height: 345px;

  .k-master-row {
    .k-radio {
      margin-left: 15px;
    }
  }
}

.view-library-material-cell {
  text-decoration: none;
}

.empty-table-state-message {
  height: 50px;
  width: 382px;
  font-family: "Open Sans", sans-serif;
  font-size: 1.3em;
  color: $brand-dark-grey;
  text-align: center;
}

/*********  Related Panel ***************/

.related-projects-container {
  .related-panel-header {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .related-panel-content {
    border: 1px solid $brand-slight-grey;
  }

  .record-header {
    display: block;
    padding: 12px 0 8px 8px;
    background-color: #f0f4f5;
    font-size: 14px;
    font-weight: bold;
    border-top: 1px solid $brand-slight-grey;
  }

  .empty-records,
  .record-group {
    display: block;
  }

  .record-group:first-of-type {
    .record-header {
      border-top: none !important;
    }
  }

  .empty-records {
    font-size: 12px !important;
  }

  .project-label {
    font-size: 14px;
    font-weight: bold;
  }

  .process-label {
    color: $brand-dark-grey;
  }

  .usage-label {
    margin-left: 3px;
    color: $brand-dark-grey;
  }

  .materials-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
  }

  .record-item {
    padding: 12px 0 8px 8px !important;
    border-top: 1px solid $brand-slight-grey;

    &:hover {
      background-color: $brand-light-grey !important;
    }
  }

  .material-link {
    display: flex;
    justify-content: space-between;
    color: $brand-dark-grey;
  }
}

.related-projects-min-width {
  min-width: 250px;
}

.used-in-projects {
  display: flex;
  justify-content: space-between;

  .chevron-button {
    margin-right: 5px !important;
  }
}

.align-right {
  text-align: right;
}

.record-specification-from-library {
  background: #f0f4f5;
  border-radius: 5px;

  p {
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
  }

  .info {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  .specification {
    font-weight: 600;
  }
}

.status-tooltip {
  text-decoration: underline;
}

.create-header .identifier {
  font-size: 14px;
  font-weight: 400;
  color: #434952;
}

.line-height-32 {
  line-height: 32px;
}

.library-status-tooltip {
  text-decoration: underline;
}

.library-status-info {
  border: 1px solid $brand-primary-color;
  /* stylelint-disable no-descending-specificity */
  /* stylelint-disable-next-line selector-class-pattern */
  .svg-inline--fa {
    color: $brand-primary-color;
  }
}

.library-status-info-icon {
  margin-left: -2px;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}

.library-spec-status.alert {
  min-height: unset !important;
  border-color: $brand-primary-color !important;
}

.library-spec-status-info-icon {
  color: $brand-primary-color;
}

.library-spec-status-close-btn {
  margin-right: -5px;
  margin-top: 5px;
  font-size: 16px;
  cursor: pointer;
  color: #859399;
}

/* stylelint-disable-next-line selector-id-pattern */
#useFromLibraryTooltip {
  /* stylelint-disable-next-line selector-class-pattern */
  .svg-inline--fa {
    color: $brand-primary-color;
    margin-left: 8px;
    margin-bottom: -1px;
  }
}

.compare-specification-ma-container {
  .row-white {
    margin-top: 30px;
    box-shadow: 1px 2px #c0c6cc;
    border-radius: 5px;
  }

  h2 {
    margin-bottom: 0;
  }

  h3 {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
  }

  .header-link {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #1f46a1;
    text-decoration: none;
  }

  .key {
    margin-left: 5px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #434952;
  }

  .header-row {
    .right {
      padding-left: 30px;
    }
  }

  .attribute-row {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;

    .attribute-name {
      color: #434952;
    }

    .attribute-value {
      color: #000;

      .warning {
        background-color: #fdfac3;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .compare-row {
    margin-top: 20px;
    margin-left: 0;
    border: 1px solid #c0c6cc;
    border-radius: 5px;

    &.warning {
      border: 1px solid #ffc107;
    }

    .col {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .col.right {
      padding-left: 30px;
    }

    .col.left {
      border-right: 1px solid #c0c6cc;
    }

    .icon-link {
      position: absolute;
      top: 19px;
      left: -15px;
      color: #859099;
    }

    .icon-warning {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  .specification-table {
    margin-bottom: 20px;

    h2 {
      display: inline-block;
      margin-bottom: 20px;
    }

    .k-grid-header {
      padding: 0 !important;
    }

    .k-grid-content {
      overflow-y: auto !important;
    }

    .column-header {
      background-color: #f0f4f5;
      font-size: 12px;
      line-height: 28px;
      font-weight: 400 !important;
      color: #434952;
    }
  }
}

.library-materials-grid.k-widget.k-grid {
  tbody tr.k-master-row:hover {
    cursor: pointer !important;
  }
}