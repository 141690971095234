/* This SCSS file is responsible for all of the CSS for the process explorer.
 */
@import "brand_colors";
@import "globals";
@import "import";


#processExplorerPage {
  #bodyDiv {
    height: unset;
  }

  .nav-bar {
    .nav-bar-item-selected {
      background-color: $brand-white;
    }
  }
}

.alert-icon {
  align-items: center;
  display: flex;
  justify-content: center;
}

#arrow {
  height: 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .bar {
    height: 45px;
    border-left: 3px solid $brand-mild-grey;
  }

  .chevron-down {
    position: absolute;
    border-right: 3px solid $brand-mild-grey;
    border-bottom: 3px solid $brand-mild-grey;
    width: 17px;
    height: 17px;
    transform: rotate(45deg);
    bottom: 4px;
  }
}

.approval-toggle-history-button {
  border: 1px solid $brand-mild-grey;

  &:hover {
    box-shadow: $box-shadow;
  }
}

.approval-toggle-history-button-icon {
  margin-right: 10px;
}


.approval-toggle-history-button {
  border: 1px solid $brand-mild-grey;

  &:hover {
    box-shadow: $box-shadow;
  }
}

.approval-toggle-history-button-icon {
  margin-right: 10px;
}

.context-menu-icon {
  height: 1em;
}

.process-explorer-container {
  display: flex;
  flex-direction: column;
  background-color: $brand-white;
  min-height: 100px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  > .error-bar {
    margin: 15px;
  }

  .dropdown-menu {
    max-height: calc(
      100vh
      - #{$trial-bar-height}
      - #{$company-header-height}
      - #{$page-title-bar-height}
      - #{$nav-bar-height}
      - #{$process-explorer-bar-height}
      - 50px
    );

    //noinspection Stylelint
    top: 100%;
  }

  .flyout-panel {
    flex-grow: 1;
    margin-top: unset;
  }
}

.process-bar {
  display: inline-table;
  background-color: $brand-light-grey;
  box-shadow: $box-shadow;
  min-height: $process-explorer-bar-height;

  @media (max-width: 767px) {
    .container {
      width: 100%;
    }
  }
}

.process-bar-center-col.col {
  flex-basis: auto;
  flex-grow: 10;
  height: 50px;
  width: auto;

  > .row {
    margin-top: 5px;
  }
}

.process-bar-panel-option {
  display: flex;
  width: 35px;
  height: 36px;
  padding: 5px 9.25px 4px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $brand-medium-grey;
  background: $brand-light-grey;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }
}

.process-bar-panel-option-selected {
  border-radius: 4px;
  background: $brand-white;
  box-shadow: 0 1px 8px 0 rgba(133, 144, 153, 0.20), 0 3px 3px 0 rgba(192, 198, 204, 0.12), 0 3px 4px 0 rgba(192, 198, 204, 0.14);

  .process-bar-panel-option-icon {
    color: $brand-dark-grey;
  }
}

.process-bar-panel-option-icon {
  color: $brand-medium-grey;
  padding: unset;
  margin: unset;
}

.process-bar-pfm-link {
  display: inline-block;
  float: right;
}

@media print {
  .process-bar-pfm-link {
    display: none;
  }
}

#processDropDownButton {
  font-size: calculateRem(18px);
  font-weight: 600;
}

.company-header-container-minified #processDropDownButton {
  font-size: calculateRem(14px);
  margin-top: 0;
  padding-left: 5px;
  padding-right: 5px;
}

.dropdown-menu-header {
  font-weight: 600;
  font-size: 12px;
  color: #434952;
}

.dropdown-item {
  & .process-bar-add {
    color: $brand-primary-color !important;
  }

  &.archived-process {
    a {
      color: #434952 !important;
      font-style: italic !important;
    }
  }

  &:has(.process-item) {
    padding: unset;
  }

  .process-item {
    display: block;
    padding: .25rem 1.5rem;
    font-family: "Open Sans", sans-serif;
  }
}

.process-explorer-graph {
  height: 100%;
}

.procexp-flow-diagram {
  height: 100%;
  margin-left: 60px;
}

@media print {
  .procexp-flow-diagram {
    margin-left: 20px;
  }
}

.procexp-bottom-bar {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 160px;
  justify-content: space-around;
  background-color: $brand-light-grey;
  border: 1px solid $brand-medium-grey;
  width: 37px;
  z-index: 3;

  //noinspection Stylelint
  position: absolute;
  left: -40px;
  bottom: 60px;
}

@media print {
  // These buttons don't add any value on a printout. They just get in the way of the tree.
  .procexp-bottom-bar {
    display: none;
  }
}

.procexp-bottom-bar-button {
  color: $brand-medium-grey;
  cursor: pointer;
  font-size: calculateRem(18px);
  margin-left: 0;
}

#processExplorerShowArchived {
  cursor: pointer;
}

#processExplorerShowArchivedLabel {
  cursor: pointer;
  font-weight: normal;
  font-size: 0.86em;
  margin-bottom: 0;
}

.link-sending-site-container {
  min-height: 40%;
  display: flex;
  margin-top: 80px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.link-sending-site-screen-footer {
  border-top: 1px solid $brand-mild-grey;
  margin-top: 3px;
  padding: 10px 30px 10px 30px !important;
}

.link-sending-site-screen-footer button {
  min-width: 140px;
}

.link-sending-site-footer-btn {
  float: right;
  margin-left: 5px;
}


.link-sending-site-selector {
  border-radius: 5px;
  border: 1px solid rgba(192, 200, 204, 1);
  width: 425px;
  padding: 15px 24px 24px 24px;
}

.link-sending-site-wizard-steps-container {
  @include rounded-border;

  padding: 0;
  margin-top: -2px;
  min-height: 600px;
  width: 100%;
}

.link-tech-transfer-error-bar {
  margin: 10px;
  width: 100%;
}

.link-sending-site-warning-container {
  margin-top: 1em;
  margin-bottom: 0;

  /* stylelint-disable-next-line selector-class-pattern */
  .svg-inline--fa {
    color: $warning-color;
  }

  .changed-attributes {
    margin-left: 1em;
  }
}

.link-sending-site-error-container {
  margin-top: 1em;
  margin-bottom: 0;

  /* stylelint-disable-next-line selector-class-pattern */
  .svg-inline--fa {
    color: $error-color;
  }

  .changed-attributes {
    margin-left: 1em;
  }
}

.record-matching-table {
  width: 100%;
  margin: 0 30px 0 30px;

  tr {
    width: 100%;
  }

  thead {
    background: $brand-light-grey;
    border: 1px solid $brand-mild-grey;

    .record-matching-table-heading {
      font-size: 18px;
      padding: 12px 0 0 12px;
    }

    .record-matching-table-subheading {
      font-weight: normal;
      padding: 0 0 12px 12px;
    }

  }

  tbody {
    border: 1px solid $brand-mild-grey;

    .record-matching-unit-operation-row {
      border-top: 2px solid $brand-mild-grey;
    }

    .record-matching-sending-site {
      padding: 10px 0 10px 10px;
      max-width: 200px;

    }

    .receiving-step {
      max-width: 200px;
    }

    .record-matching-receiving-site {
      padding: 10px 10px 10px 0;
    }

    .step {
      text-align: right;
    }
  }

  .arrow-row {
    width: 100px;

    #arrow {
      height: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      .bar {
        height: 3px;
        border-left: 95px solid $brand-mild-grey;
      }

      .chevron-right {
        position: absolute;
        border-right: 3px solid $brand-mild-grey;
        border-bottom: 3px solid $brand-mild-grey;
        width: 17px;
        height: 17px;
        transform: rotate(-45deg);
        right: 3px;
      }
    }
  }

}

.static-panel-title-bar-hidden {
  margin-top: unset !important;

  .static-panel-title-bar-container {
    display: none;
  }
}

.process-explorer-container {
  .static-panel-title-bar-hidden {
    .quick-panel-static-container {
      position: relative;
    }
  }
}

#processPage {
  height: 100vh !important;
  overflow-y: auto !important;
  background: #f0f4f5 !important;
}
